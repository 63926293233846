import {AfterViewInit, Component, QueryList, ViewChildren} from '@angular/core';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';

export interface MenuItem {
  link?: string; // Le lien vers la route
  linkIcon?: string; // Classe de l'icône à utiliser
  linkTranslate?: string; // Clé pour la traduction
  subMenu?: MenuItem[]; // Sous-menu
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit {
  sideBarMenu: MenuItem[] = [
    {
      link: 'dashboard',
      linkIcon: 'nav-icon fad fa-gauge-simple-high',
      linkTranslate: 'core.components.navbar.dashboard',
    },
    {
      link: 'companies',
      linkIcon: 'nav-icon fad fa-building',
      linkTranslate: 'core.components.navbar.companies',
    },
    {
      link: 'activity-areas',
      linkIcon: 'nav-icon fad fa-industry',
      linkTranslate: 'core.components.navbar.activity-areas',
    },
    {
      link: 'legal-documents',
      linkIcon: 'nav-icon fad fa-file-alt',
      linkTranslate: 'core.components.navbar.legal-documents',
    },
    {
      link: 'requests',
      linkIcon: 'nav-icon fad fa-suitcase',
      linkTranslate: 'core.components.navbar.requests',
    },
    {
      link: 'contracts',
      linkIcon: 'nav-icon fad fa-handshake',
      linkTranslate: 'core.components.navbar.contracts',
    },
    {
      link: 'purchase-orders',
      linkIcon: 'nav-icon fad fa-file-invoice',
      linkTranslate: 'core.components.navbar.purchase-orders',
    },
    {
      linkTranslate: 'core.components.navbar.marketplace-title',
      subMenu: [
        {
          link: '/marketplace/calls-for-tender',
          linkIcon: 'nav-icon fad fa-newspaper',
          linkTranslate: 'core.components.navbar.marketplace.calls-for-tender',
        },
        {
          link: '/marketplace/leagues',
          linkIcon: 'nav-icon fad fa-landmark',
          linkTranslate: 'core.components.navbar.marketplace.leagues',
        },
      ],
    },
    {
      linkTranslate: 'core.components.navbar.invoices-title',
      subMenu: [
        {
          link: '/invoicing/specific',
          linkIcon: 'nav-icon fad fa-file-invoice',
          linkTranslate: 'global.common.specific-invoice',
        },
        {
          link: '/invoicing/supplier',
          linkIcon: 'nav-icon fad fa-newspaper',
          linkTranslate: 'global.common.supplier',
        },
        {
          link: '/invoicing/buyer',
          linkIcon: 'nav-icon fad fa-landmark',
          linkTranslate: 'global.common.client',
        },
        {
          link: '/invoicing/internal',
          linkIcon: 'nav-icon fad fa-house',
          linkTranslate: 'global.common.billable-company',
        },
      ],
    },
    {
      linkTranslate: 'core.components.navbar.credit-notes-title',
      subMenu: [
        {
          link: '/invoicing/credit-note/supplier',
          linkIcon: 'nav-icon fad fa-newspaper',
          linkTranslate: 'global.common.supplier',
        },
        {
          link: '/invoicing/credit-note/buyer',
          linkIcon: 'nav-icon fad fa-landmark',
          linkTranslate: 'global.common.client',
        },
        {
          link: '/invoicing/credit-note/internal',
          linkIcon: 'nav-icon fad fa-house',
          linkTranslate: 'global.common.billable-company',
        },
      ],
    },
    {
      linkTranslate: 'core.components.navbar.activity-report.title',
      subMenu: [
        {
          link: '/activity-report/activity-sheets',
          linkIcon: 'nav-icon fad fa-newspaper',
          linkTranslate: 'core.components.navbar.activity-report.activity-sheet',
        },
        {
          link: 'fees',
          linkIcon: 'nav-icon fad fa-wallet',
          linkTranslate: 'core.components.navbar.fees',
        },
        {
          link: 'on-call',
          linkIcon: 'nav-icon fad fa-file-alt',
          linkTranslate: 'core.components.navbar.on-call',
        },
      ],
    },
    {
      linkTranslate: 'Administration',
      subMenu: [
        {
          link: 'tax-configuration-country',
          linkIcon: 'nav-icon fa fa-earth-europe',
          linkTranslate: 'core.components.navbar.tax-configuration-country',

        },
        {
          link: 'mailing',
          linkIcon: 'nav-icon fad fa-envelope',
          linkTranslate: 'core.components.navbar.invoices.mails',
        },
        {
          link: 'reports',
          linkIcon: 'nav-icon fad fa-newspaper',
          linkTranslate: 'global.common.reports',
        },
        {
          link: 'users',
          linkIcon: 'nav-icon fad fa-users',
          linkTranslate: 'global.common.users',
        },
      ],
    },
  ];
  @ViewChildren(BsDropdownDirective) dropdownList: QueryList<BsDropdownDirective>;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dropdownList.forEach(dropdown => {
        dropdown.show();
        setTimeout(() => {
          dropdown.hide();
        });
      });
    });
  }
}
