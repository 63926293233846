import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IsupplierSharedModule} from '@i-supplier/angular-shared-module';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgApexchartsModule} from 'ng-apexcharts';
import {AvatarModule} from 'ngx-avatars';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ImageCropperComponent, ImageCropperModule} from 'ngx-img-cropper';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../../assets/environments/environment';
import {AlertComponent} from './components/alert/alert.component';
import {DefaultVariablesModalComponent} from './components/default-variables-modal/default-variables-modal.component';
import {
  BillingAddressDropdownComponent,
} from './components/dropdowns/billing-address-dropdown/billing-address-dropdown.component';
import {
  TemplateFileTemplatesDropdownComponent,
} from './components/dropdowns/templates/template-file-templates-dropdown/template-file-templates-dropdown.component';
import {
  TemplateSignatoryDropdownComponent,
} from './components/dropdowns/templates/template-signatory-dropdown/template-signatory-dropdown.component';
import {
  TemplateTypesDropdownComponent,
} from './components/dropdowns/templates/template-types-dropdown/template-types-dropdown.component';
import {KpiAvatarComponent} from './components/kpi-avatar/kpi-avatar.component';
import {KpiCardComponent} from './components/kpi-card/kpi-card.component';
import {ListContentComponent} from './components/list-content/list-content.component';
import {MessageComponent} from './components/message/message.component';
import {ModalConfirmComponent} from './components/modal-confirm/modal-confirm.component';
import {ModalIdleWarningComponent} from './components/modal-idle-warning/modal-idle-warning.component';
import {NavDropdownComponent} from './components/nav-dropdown/nav-dropdown.component';
import {NoContentComponent} from './components/no-content/no-content.component';
import {NoDataComponent} from './components/no-data/no-data.component';
import {NotificationToastrComponent} from './components/notification-toastr/notification-toastr.component';
import {PageMenuComponent} from './components/page-menu/page-menu.component';
import {PageSectionTitleComponent} from './components/page-section-title/page-section-title.component';

import {PageTitleComponent} from './components/page-title/page-title.component';
import {PaginationSizeComponent} from './components/pagination-size/pagination-size.component';
import {ProfilePictureComponent} from './components/profile-picture/profile-picture.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {TemplateCardComponent} from './components/template-card/template-card.component';
import {UserRibbonComponent} from './components/user-ribbon/user-ribbon.component';
import {VerticalPageMenuComponent} from './components/vertical-page-menu/vertical-page-menu.component';
import {DynamicInputWidthDirective} from './directives/dynamic-input-width.directive';
import {EmailAndPhoneFinderDirective} from './directives/email-and-phone-finder.directive';
import {BooleanFormatPipe} from './pipes/boolean-format.pipe';
import {CurrencyFormatPipe} from './pipes/currency-format.pipe';
import {DefaultPipe} from './pipes/default.pipe';
import {EllipsisPipe} from './pipes/ellipsis.pipe';
import {LocalizedCurrencyPipe} from './pipes/localized-currency/localized-currency.pipe';
import {LocalizedDatePipe} from './pipes/localized-date/localized-date.pipe';
import {SanitizeHtmlPipe} from './pipes/sanitize/sanitize-html.pipe';
import {StatusPipe} from './pipes/status.pipe';
import {
  FeeValidationTypeSelectionComponent
} from './components/fee-validation-type-selection/fee-validation-type-selection.component';

@NgModule({
  declarations: [
    AlertComponent,
    MessageComponent,
    PageTitleComponent,
    UserRibbonComponent,
    NoContentComponent,
    PageMenuComponent,
    CurrencyFormatPipe,
    BillingAddressDropdownComponent,
    DefaultPipe,
    BooleanFormatPipe,
    ProfilePictureComponent,
    StatusPipe,
    NoDataComponent,
    SpinnerComponent,
    EllipsisPipe,
    EmailAndPhoneFinderDirective,
    ModalConfirmComponent,
    ListContentComponent,
    KpiCardComponent,
    NotificationToastrComponent,
    KpiAvatarComponent,
    PageSectionTitleComponent,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
    VerticalPageMenuComponent,
    NavDropdownComponent,
    ModalIdleWarningComponent,
    SanitizeHtmlPipe,
    TemplateCardComponent,
    TemplateTypesDropdownComponent,
    TemplateSignatoryDropdownComponent,
    TemplateFileTemplatesDropdownComponent,
    DefaultVariablesModalComponent,
    PaginationSizeComponent,
    DynamicInputWidthDirective,
    FeeValidationTypeSelectionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AlertModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 5000,
      preventDuplicates: false,
      closeButton: true,
      progressBar: true,
      tapToDismiss: false,
      extendedTimeOut: 5000,
    }),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgSelectModule,
    AvatarModule,
    NgApexchartsModule,
    NgxIntlTelInputModule,
    ImageCropperModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    FontAwesomeModule,
    IsupplierSharedModule.forRoot({
      api_root: environment.api_root,
    }),
    FontAwesomeModule,
  ],
  exports: [
    FormsModule,
    IsupplierSharedModule,
    ReactiveFormsModule,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
    UserRibbonComponent,
    AlertComponent,
    PageTitleComponent,
    NoContentComponent,
    ProfilePictureComponent,
    PageMenuComponent,
    SpinnerComponent,
    StatusPipe,
    EllipsisPipe,
    PaginationSizeComponent,
    EmailAndPhoneFinderDirective,
    CurrencyFormatPipe,
    DefaultPipe,
    BooleanFormatPipe,
    ToastrModule,
    PaginationModule,
    TooltipModule,
    PopoverModule,
    AlertModule,
    ProgressbarModule,
    NgxIntlTelInputModule,
    AvatarModule,
    ImageCropperModule,
    ImageCropperComponent,
    NoDataComponent,
    ModalModule,
    NgSelectModule,
    BsDatepickerModule,
    BillingAddressDropdownComponent,
    InfiniteScrollModule,
    ListContentComponent,
    KpiCardComponent,
    KpiAvatarComponent,
    MessageComponent,
    PageSectionTitleComponent,
    VerticalPageMenuComponent,
    TranslateModule,
    NgApexchartsModule,
    NavDropdownComponent,
    ModalIdleWarningComponent,
    SanitizeHtmlPipe,
    TemplateCardComponent,
    TemplateTypesDropdownComponent,
    TemplateSignatoryDropdownComponent,
    TemplateFileTemplatesDropdownComponent,
    DefaultVariablesModalComponent,
    DynamicInputWidthDirective,
    FeeValidationTypeSelectionComponent
  ],
  providers: [StatusPipe],
  entryComponents: [ModalConfirmComponent, NotificationToastrComponent]
})
export class SharedModule {}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
