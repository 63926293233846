export const commonProperties = {
  token: 'access_token',
  token_expiration: 'access_token_expiration',
  login: 'authMgtApi/login',
  logout: 'authMgtApi/logout',
  tokenValidation: 'authMgtApi/token',
  forgetPassword: 'authMgtApi/forget-password',
  refreshToken: 'authMgtApi/refresh-token',
  activateAccount: 'authMgtApi/activate-account',
  resetPassword: 'authMgtApi/reset-password',
  userById: 'authMgtApi/issuers/:userId',

  companyCreation: 'enterpriseMgtApi/companies',
  companiesList: 'enterpriseMgtApi/companies',
  companyById: 'enterpriseMgtApi/companies/:companyId',
  legalForms: 'enterpriseMgtApi/companies/legal-forms',
  relatedEntities: 'enterpriseMgtApi/companies/entities',
  billingConfig: 'enterpriseMgtApi/billing-params',
  billingConfigById: 'enterpriseMgtApi/billing-params/:billingParamId',
  billingAddress: 'enterpriseMgtApi/companies/:companyId/addresses',
  billingAddressId: 'enterpriseMgtApi/companies/:companyId/addresses/:id',
  billingAddressDisable: 'enterpriseMgtApi/companies/:companyId/addresses/:addressId/disable',

  contractList: 'contractMgtApi/contracts',
  contractUpdate: 'contractMgtApi/contracts/:contractId',
  contractTemplateList: 'contractMgtApi/templates',
  contractTemplateFileVariables: 'contractMgtApi/templates/variables',
  contractTemplateVariables: 'contractMgtApi/templates/:templateId/variables',
  contractTemplateById: 'contractMgtApi/templates/:templateId',
  templateGroupList: 'contractMgtApi/templates/groups',
  templateGroupPreview: 'contractMgtApi/templates/group-preview',
  templateGroupById: 'contractMgtApi/templates/groups/:groupId',
  templateGroupByIdTemplate: 'contractMgtApi/templates/groups/:groupId/template',

  contractVersions: 'contractMgtApi/contract-versions',
  templateContractVersionsFile: 'contractMgtApi/contract-versions/:contractVersionId/files',
  templateContractVersionsPreview: 'contractMgtApi/contract-versions/:contractVersionId/preview',
  templateContractVersionsDownload: 'contractMgtApi/contract-versions/:contractVersionId/files/:fileId/template',
  templateContractVersionsDelete: 'contractMgtApi/contract-versions/:contractVersionId/files/:contractVersionFileId',
  templateContractVersionsUploadFile: 'contractMgtApi/contract-versions/:contractVersionId/files/:fileId',
  templateContractVersionsPreviewFile: 'contractMgtApi/contract-versions/:contractVersionId/files/:fileId/preview',
  templateContractVersionsValidation: 'contractMgtApi/contract-versions/:contractVersionId/validate',
  templateContractVersionsRegeneration: 'contractMgtApi/contract-versions/:contractVersionId/regenerate',

  contractDraftFramework: 'contractMgtApi/contracts/draft',
  cancelFrameworkAgreement: 'contractMgtApi/contracts/:contractId/cancel',
  contractAction: 'contractMgtApi/contracts/:contractId/actions',
  renewContract: 'contractMgtApi/contracts/:contractId/renew',
  contractsToExpiryKpi: 'contractMgtApi/contracts/contract-about-to-expire',
  contractToValidateKpi: 'contractMgtApi/kpis/contract-waiting',
  contractRefusedBySupplierKpi: 'contractMgtApi/kpis/contract-by-status',

  notification_api: 'notificationMgtApi',
  lang: 'authMgtApi/users/:employeeId/language',
  superset: 'reportMgtApi/superset',
  superset_reports: 'reportMgtApi/superset/reports',

  additionalDocuments: 'legalDocumentsMgtApi/additional-documents',
  additionalDocumentsUpdate: 'legalDocumentsMgtApi/additional-documents/:additionalDocumentId',
  additionalDocumentsAttachmentByRequestId: 'legalDocumentsMgtApi/additional-documents/:requestId/attachments',
  attachmentByRequestId: 'legalDocumentsMgtApi/additional-documents/searchAttachment',
  moderateAdditionalDocumentsAttachmentById: 'legalDocumentsMgtApi/additional-documents/:additionalDocumentId/attachments/:attachmentId',

  tags: 'enterpriseMgtApi/tags',
  tagById: 'enterpriseMgtApi/tags/:tagId',

  onCall: 'enterpriseMgtApi/on-call-params',
  onCallById: 'enterpriseMgtApi/on-call-params/:onCallId',
  onCallActive: 'enterpriseMgtApi/on-call-params/active',
  activityReportOnCalls: 'activityReportMgtApi/on-calls',
  onCallsEnvelope: 'activityReportMgtApi/request-envelope?activityType=:activityType',
  onCallFileById: 'activityReportMgtApi/on-calls/:onCallId/file',
  cancelValidatedOnCall: 'activityReportMgtApi/on-calls/cancelValidatedOnCall/:onCallId',
  onCallByIdDelete: 'activityReportMgtApi/on-calls/:onCallId',
  submittedFeeCount: 'activityReportMgtApi/kpis/fee-sa-moderation',

  permissions: 'enterpriseMgtApi/permissions',

  userEmailTest: 'authMgtApi/status',

  suppliersList: 'enterpriseMgtApi/companies',

  employeesList: 'enterpriseMgtApi/employees',
  authoritiesByCompanyType: 'authMgtApi/authorities',
  employeeById: 'enterpriseMgtApi/employees/:employeeId',
  accountByEmployeeId: 'authMgtApi/users',
  employeePictureById: 'enterpriseMgtApi/employees/:employeeId/picture',
  employeeRoleById: 'authMgtApi/users/:employeeId',
  employeeSuppliersById: 'enterpriseMgtApi/employees/:employeeId/suppliers',

  request: 'requestMgtApi/requests',
  requestById: 'requestMgtApi/requests/:requestId',
  requestClientStepById: 'requestMgtApi/requests/clientStep/:requestId',
  requestSupplierStepById: 'requestMgtApi/requests/supplierStep/:requestId',
  requestGenerateActivitySheetById: 'requestMgtApi/requests/:requestId/generate-activity-sheet',

  requestClientByRequestId: 'requestMgtApi/requests/:requestId/clients',
  requestBillingByRequestId: 'requestMgtApi/requests/:requestId/billings',
  requestSupplierByRequestId: 'requestMgtApi/requests/:requestId/suppliers/:supplierId',
  requestProjectByRequestId: 'requestMgtApi/requests/:requestId/projects',
  requestProjectByRequestIdByProjectId: 'requestMgtApi/requests/:requestId/projects/:projectId',
  requestProjectByProjectId: 'requestMgtApi/projects/:projectId',
  projectListByRequestId: 'requestMgtApi/projects',
  providerByRequestId: 'requestMgtApi/requests/:requestId/providers',
  providerByRequestIdByProjectIdByProviderId: 'requestMgtApi/requests/:requestId/project/:projectId/providers/:providerId',
  providerByRequestIdByProviderId: 'requestMgtApi/requests/:requestId/providers/:providerId',
  providerResume: 'requestMgtApi/requests/:requestId/providers/:providerId/resume',

  requestHistoryStatus: 'requestMgtApi/requests/:requestId/history',
  requestStatus: 'requestMgtApi/requests/status',
  requestHistoryChangeStatus: 'requestMgtApi/requests/:requestId/status',
  requestHistoryCancelStatus: 'requestMgtApi/requests/:requestId/cancel',
  totalRequestSa: 'requestMgtApi/kpis/total-request-sa',
  totalRequestRequiringContractGeneration: 'requestMgtApi/kpis/total-contract-to-generate',
  totalRequestRequiringPurchaseOrderGeneration: 'requestMgtApi/kpis/total-purchase-order-to-generate',
  totalInvoicesToCredit: 'invoicingMgtApi/kpis/invoices-to-credit',
  relaunches: 'requestMgtApi/kpis/relaunches',
  actionRepartition: 'requestMgtApi/kpis/action-repartition',

  exportRequestsToCsv: 'requestMgtApi/requests/export-list-to-csv',

  // Marketplace
  callsForTender: 'marketplaceMgtApi/callsForTender',
  categoryList: 'marketplaceMgtApi/categories',
  callsForTenderById: 'marketplaceMgtApi/callsForTender/:callForTenderId',
  callsForTenderModerate: 'marketplaceMgtApi/callsForTender/:callForTenderId/moderate',
  callsForTenderOffers: 'marketplaceMgtApi/offers',
  callsForTenderOfferById: 'marketplaceMgtApi/offers/:callForTenderOfferId/attachment',
  callsForTenderAttachments: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachments',
  callsForTenderOfferModerate: 'marketplaceMgtApi/offers/:callForTenderOfferId/moderate',
  callForTendersToModerate: 'marketplaceMgtApi/kpis/call-for-tenders/moderate',
  offersToModerateKpi: 'marketplaceMgtApi/kpis/offers/moderate',
  callsForTenderFile: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachment',
  callsForTenderFiles: 'marketplaceMgtApi/callsForTender/:callForTenderId/attachments',
  callsForTenderView: 'marketplaceMgtApi/callsForTender/view/:callForTenderId',
  callsForTenderActions: 'marketplaceMgtApi/callsForTender/:callForTenderId/actions',
  downloadCFTAttachment: 'marketplaceMgtApi/callsForTender/:id/cft-attachment-download',
  updateCFTAttachment: 'marketplaceMgtApi/callsForTender/:callForTenderId/update-attachments',

  leagues: 'marketplaceMgtApi/leagues',
  leaguesById: 'marketplaceMgtApi/leagues/:leagueId',

  // Invoice
  billableCompanies: 'invoicingMgtApi/invoice-configs/billable-companies',
  billableCompanyById: 'invoicingMgtApi/invoice-configs/billable-companies/:id',
  invoicesToValidateKpi: 'invoicingMgtApi/kpis/invoices-to-validate',
  discountBusinessPlans: 'invoicingMgtApi/discount-business-plans',
  discountBusinessPlanById: 'invoicingMgtApi/discount-business-plans/:discountBusinessPlanId',
  invoicesNotForwardedKpi: 'invoicingMgtApi/kpis/invoices-not-forwarded',

  // Purchase order
  purchaseOrderPostSigned: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/signed',
  purchaseOrderTemplateList: 'purchaseOrderMgtApi/templates',
  purchaseOrderTemplateById: 'purchaseOrderMgtApi/templates/:templateId',
  purchaseOrderById: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId',
  purchaseOrderFileById: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/file',
  purchaseOrdersList: 'purchaseOrderMgtApi/purchase-orders',
  purchaseFieldsValues: 'purchaseOrderMgtApi/fields-values',
  comments: 'purchaseOrderMgtApi/comments',
  commentCreation: 'purchaseOrderMgtApi/comments',
  commentById: 'purchaseOrderMgtApi/comments/:commentId',
  purchaseOrderActions: 'purchaseOrderMgtApi/purchase-orders/:purchaseOrderId/actions',
  totalPurchaseOrderToValidate: 'purchaseOrderMgtApi/kpis/purchase-order-waiting',


  // activity report
  activitySheets: 'activityReportMgtApi/activitysheet',
  activitySheetById: 'activityReportMgtApi/activitysheet/:activitySheetId',
  activitySheetReminder: 'activityReportMgtApi/activitysheet/:activitySheetId/reminder',

  // fee
  feesList: 'activityReportMgtApi/fees',
  feeById: 'activityReportMgtApi/fees/:feeId',
  feeFileById: 'activityReportMgtApi/fees/:feeId/file',
  cancelValidatedFee: 'activityReportMgtApi/fees/cancelValidatedFee/:feeId',

  // Users
  getListAllSA: 'authMgtApi/users/get-sa',
  updateSaUserStatus: 'authMgtApi/users/update-sa/:userId',
  createSaUser: 'authMgtApi/register',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
