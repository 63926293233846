import {Component, Input} from '@angular/core';
import {MenuItemModel} from '../../models/menu-item.model';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
  styleUrls: ['./page-menu.component.scss']
})
export class PageMenuComponent {
  @Input() menuList: MenuItemModel[];


  public isActive(link: string): string {
    return window.location.pathname.includes('/' + link + '/') ? 'active' : '';
  }

  get hasChildrens() {
    return this.menuList.filter(menu => menu.children && menu.children.length > 0).length > 0;
  }
}
