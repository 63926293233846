import {Component, ElementRef, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {faPrint} from '@fortawesome/pro-duotone-svg-icons';
import {ButtonTypeEnum, IspColoursEnum} from '@i-supplier/angular-shared-module';
@Component({
  selector: 'app-default-variables-modal',
  templateUrl: './default-variables-modal.component.html',
  styleUrls: ['./default-variables-modal.component.scss']
})
export class DefaultVariablesModalComponent {
  @ViewChild('printSection') printSection: ElementRef;
  variableKeys = [];
  protected readonly ButtonColour = IspColoursEnum;
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly faPrint = faPrint;

  constructor(public modalRef: BsModalRef, private translateService: TranslateService) {
    this.variableKeys = Object.entries(this.translateService.instant('company.template.defaultVariables'))
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => (a.key > b.key) ? 1 : -1);
  }

  printVariables() {
    const printContent = this.printSection.nativeElement.innerHTML;
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <style>
              body { font-family: Montserrat, sans-serif; }
              .table-sm { width: 100%; border-collapse: collapse; }
              .table-sm th, .table-sm td { border: 1px solid #000; padding:4px; text-align: left; }
              .table-sm th { background-color: #f2f2f2; }
            </style>
          </head>
          <body>
            ${printContent}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();

      // Handling cancellation of the print dialog
      printWindow.onbeforeunload = () => {
        printWindow.close();
        return null;
      };

      // Trigger the print dialog
      printWindow.print();
    }
  }
}
