<div class="row">
  <div class="col-12">
    <ul class="nav nav-tabs">
      <ng-container *ngFor="let item of menuList">
        <li class="nav-item" *ngIf="!item.children || item.children.length>0">
          <div class="tab-link tab-label" [ngClass]="{'tab-parent': hasChildrens}" routerLinkActive="active" [routerLink]="item.link">
            {{ item.title | translate }}
            <fa-duotone-icon class="ml-2" [icon]="item.icon" *ngIf="item.icon"></fa-duotone-icon>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
  <div class="col-12 mt-2" *ngIf="hasChildrens">
    <ul class="nav nav-tabs">
      <ng-container *ngFor="let parent of menuList">
        <ng-container *ngFor="let item of parent.children">
          <li class="nav-item" *ngIf="isActive(parent.link)">
            <div class="tab-link tab-label tab-child" routerLinkActive="active" [routerLink]="item.link">
              {{ item.title | translate }}
              <fa-duotone-icon class="ml-2" [icon]="item.icon" *ngIf="item.icon"></fa-duotone-icon>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</div>
